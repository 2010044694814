import React from 'react';

const TC = () => {
    return (
        <>
            <section className="h-screen bg-[#241C24] bg-cover" style={{backgroundImage: "url(./assets/Images/plainbg.png)"}}>
                <div className="custom-container ">
                    <div className="pt-[150px]">
                        <h1 className="uppercase text-3xl font-bold text-white text-center mb-[40px]">Terms and Conditions</h1>
                        <p className="text-white text-lg text-center font-semibold">text to be added</p>
                    </div>
                </div>
            </section>  
        </>
    );
}

export default TC;
