import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { baseUrl } from '../Utils/app';
import ItemCard from './Components/ItemCard';

const Musics = () => {

    let loadingToast;
    const [loading,setLoading] = useState(false);
    const [musics, setMusics] = useState([]);
    const [featured, setFeatured] = useState();

    useEffect(()=>{
        setLoading(true);
        loadingToast = toast.loading("Loading");

        const getItems = async () => {
           return await axios.get(`${baseUrl}/api/musics`)
        }

        getItems().then((resp) => {
            setMusics(resp.data.data.musics)
            toast.dismiss(loadingToast)
            setFeatured(resp.data.data.featured)
            setLoading(false);
        }).catch((error)=>{
            console.log(error)
            toast.dismiss(loadingToast)
            toast.error(error?.message);
        });
    },[])
    
    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[]);

    return (
        <>
            {
                !loading ? (
                    <>
                        {/* <Swiper
                            modules={[Navigation, Pagination, A11y]}
                            spaceBetween={0}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                            >
                                {
                                    featured.length > 0 && featured.map((item) =>  <SwiperSlide>
                                            <section className="h-screen bg-[#241C24] bg-cover" style={{backgroundImage: "url(./assets/Images/musicBg.png)"}}>
                                                <div className="custom-container flex items-center h-full">
                                                    <div className="w-[50%] min-w-[500px]">
                                                        <h2 className="text-2xl tracking-[15px] text-white ">Music</h2>
                                                        <h1 className="text-[#18AD58] text-[50px] font-bold uppercase"> <Link to={"/musicPlayer/"+item.id}>{item?.title}</Link> </h1>
                                                        <p className="text-white text-xl mb-7">{item?.author}</p>
                                                        <Link to={"/musicPlayer/"+item.id} className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold">LISTEN NOW</Link>
                                                    </div>
                                                </div>
                                            </section>
                                        </SwiperSlide>
                                    )
                                }
                        </Swiper> */}
                        <section className="h-screen bg-[#241C24] bg-cover" style={{backgroundImage: `url(${featured?.cover_image_large})`}}>
                            <div className="custom-container flex items-center h-full">
                                <div className="w-[50%] min-w-[500px]">
                                    <h2 className="text-2xl tracking-[15px] text-white uppercase">Music</h2>
                                    <h1 className="text-[#18AD58] text-[50px] font-bold uppercase"> <Link to={"/musicPlayer/"+featured?.id}>{featured?.title}</Link> </h1>
                                    <p className="text-white text-xl mb-7">{featured?.author}</p>
                                    <Link to={"/musicPlayer/"+featured?.id} className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold">LISTEN NOW</Link>
                                </div>
                            </div>
                        </section>
                        <section className="pt-[100px] pb-[50px] bg-[#241C24] mt-[-2px]" style={{backgroundImage: "url(/assets/Images/sec3bg.png)"}}>
                            <div className="custom-container">
                                <div className=" mx-auto">
                                    <div className="flex justify-between mb-[44px]">
                                        <h2 className=" text-white text-3xl font-bold uppercase">Music</h2>
                                    </div>
                                    <div className="grid grid-cols-5 gap-x-[30px] gap-y-[40px]">
                                        {
                                            musics?.length > 0 && musics?.map(
                                                (item) => <ItemCard routeName={"/musicPlayer/"} item={item}></ItemCard>
                                            )
                                        }
                                    </div>
                                    <div className="mt-[80px]">
                                        <button className=" block bg-[#18AD58] px-[40px] py-[5px] text-[16px] rounded-md text-white font-semibold uppercase mx-auto">Show More</button>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </>
                ):(
                    <section className="h-screen bg-[#241C24] bg-cover flex items-center justify-center" style={{backgroundImage: 'url(./assets/Images/plainbg.png)'}}>
                        <img src={'/assets/Images/Eclipse-1s-200px.svg'}></img>
                    </section>
                ) 
            }
        </>
    );
}

export default Musics;
