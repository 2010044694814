import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { baseUrl } from '../Utils/app';
import toast from 'react-hot-toast';
import axios from 'axios'
import { db } from '../Utils/firebase';

const CreateAccount2 = ({setCancelModal}) => {
    const navigate = useNavigate();

    const [formControls,setFormControls] = useState({
        'email':'',
        'password':'',
    });
    const [loading,setLoading] = useState(false);
    let loadingToast;
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        let postData = {
            ...formControls,
        }

        const usersRef = db.ref('users'); // Reference to 'users' node
        const newUserRef = usersRef.push()

        newUserRef.set(postData) // Adds the user object with a unique ID
          .then(() => {
            const userId = newUserRef.key;
            console.log(userId);

            setLoading(false)
            toast.dismiss(loadingToast)
            toast.success("Account Created Successfully");
            setFormControls({
                'email':'',
                'password':'',
            });
            navigate('/subscribe2',{state:{userId:userId}})
          })
          .catch((error) => {
            console.log(error)
            toast.dismiss(loadingToast)
            toast.error(error?.message);
            setLoading(false)
          });
    }

    const handleLogin = () => {
        navigate('/subscribe2')
    };

    return (
        <div className=' bg-[#BFBEBE] flex items-center justify-center absolute w-full h-full top-0 left-0 bg-opacity-60'>
            <div className='w-[800px] bg-white rounded-lg flex' >
                <div className='w-[300px] bg-cover pt-[50px] px-[30px] flex flex-col justify-between' style={{backgroundImage: "url(/assets/Images/CreateAccImg1.png)"}}>
                    <div>
                        <img width={40} className='block mx-auto' src='/assets/Images/CreateAccImg2.png' />

                        <p className='font-bold text-white mt-[40px] mb-[30px] text-lg'>Get unlimited access in 3 easy steps:</p>

                        <ul className='text-base'>
                            <li className='flex gap-x-2 items-center text-white mb-2'>
                                <div className='w-[20px] h-[20px] bg-[#7FB07F] rounded-full flex items-center justify-center'>
                                    <p className='text-white'>1.</p>
                                </div>
                                <span>Create your account</span>
                            </li>
                            <li className='flex gap-x-2 items-center text-white mb-2'>
                                <div className='w-[20px] h-[20px] bg-gray-300 rounded-full flex items-center justify-center'>
                                    <p className='text-white'>2.</p>
                                </div>
                                <span>Verify your account</span>
                            </li>
                            <li className='flex gap-x-2 items-center text-white mb-2'>
                                <div className='w-[20px] h-[20px] bg-gray-300 rounded-full flex items-center justify-center'>
                                    <p className='text-white'>3.</p>
                                </div>
                                <span>Access exclusive content</span>
                            </li>
                        </ul>
                    </div>
                    
                    <div>
                        <img width="150" className='block mx-auto py-[50px]' src="/assets/Images/logo.png" />
                    </div>
                    
                </div>
                <form onSubmit={(e)=> handleSubmit(e)} className='pt-[100px] pb-[20px] px-[50px] flex-shrink-0 flex-1'>
                    <h1 className='mb-[40px] text-gray-500 text-3xl text-center font-semibold'>Create your account</h1>
                    <div className='mb-[30px] border border-gray-300 flex items-center p-3 gap-x-2 rounded-md'>
                        <img className='w-[20px] block flex-shrink-0 bg-transparent ' width="30" src="/assets/Images/CreateAccImg3.png" />
                        <input required value={formControls.email} onChange={(e) => setFormControls({...formControls, 'email':e.target.value})} className=' block w-full focus:outline-none' placeholder='Fill in your email' />
                    </div>
                    <div className='mb-[30px] border border-gray-300 flex items-center p-3 gap-x-2 rounded-md'>
                        <img className='w-[20px] block flex-shrink-0 bg-transparent ' width="30" src="/assets/Images/CreateAccImg4.png" />
                        <input type='password' required value={formControls.password} onChange={(e) => setFormControls({...formControls, 'password':e.target.value})} className=' block w-full focus:outline-none' placeholder='Create your password' />
                    </div>
                    {/* <button onClick={() => handleSubmit()} className=" bg-[#18AD58] px-[50px] py-[15px] text-2xl rounded-full text-white font-bold uppercase block mx-auto">Continue</button> */}
                    {
                        loading ? (
                            <button type='button' className=" bg-[#18AD58] px-[50px] py-[15px] text-2xl rounded-full text-white font-bold uppercase block mx-auto">
                                <img width={35} src={'/assets/Images/Eclipse-1s-200px.svg'}></img>
                            </button>
                        ): (
                            <button type='submit' className=" bg-[#18AD58] px-[50px] py-[15px] text-2xl rounded-full text-white font-bold uppercase block mx-auto">Continue</button>
                        )
                    }
                    <p className='text-gray-500 text-sm w-[80%] mx-auto text-center mt-[50px]'>By clicking the button, you agree to the <Link className='underline' to={"/terms-condition"}>Terms and Conditions</Link>, <Link className='underline' to={"/privacy"}>Privacy Policy</Link> and <Link to={"/cookie"} className='underline'>Cookie Policy</Link>.
                        You confirm you are 18 years or older and agree to receive emails from onehubplay.com.
                    </p>
                </form>
                
            </div>
        </div>
    );
}

export default CreateAccount2;
