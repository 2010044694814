import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from './Pages/Index';
import Home from './Pages/Home';
import Nav from './Layout/Nav'
import Footer from './Layout/Footer'
import AudioBookPlayer from './Pages/AudioBookPlayer'
import AudioBooks from './Pages/AudioBooks'
import Cookie from './Pages/Cookie'
import EBookReader from './Pages/E-bookReader'
import Ebooks from './Pages/E-books'
import FAQ from './Pages/FAQ'
import MoviePlayer from './Pages/MoviePlayer'
import Movies from './Pages/Movies'
import Musics from './Pages/Musics'
import MusicPlayer from './Pages/MusicPlayer'
import MyAccount from './Pages/MyAccount'
import Privacy from './Pages/Privacy'
import TC from './Pages/T&C'
import Register from './Pages/Register';
import Subscribe from './Pages/Subscribe';
import Subscribe2 from './Pages/Subscribe2';
import CancelSubscription from './Pages/CancelSubscription';
import UploadItem from './Pages/UploadItem';
import ListItems from './Pages/ListItems';
import { Toaster } from 'react-hot-toast';
import Registration from './Pages/Registration';
import axios from 'axios'


function App() {
  axios.defaults.withCredentials = true;
  return (
    <BrowserRouter>
      <Nav></Nav>
      <Routes>
        <Route path="/" index element={<Index />}></Route>
        <Route path='/audioBookPlayer/:id' element={<AudioBookPlayer />}/>
        <Route path='/home' element={<Home />}/>
        <Route path='/audioBooks' element={<AudioBooks />}/>
        <Route path='/cookie' element={<Cookie />}/>
        <Route path='/eBookReader/:id' element={<EBookReader />}/>
        <Route path='/ebooks' element={<Ebooks />}/>
        <Route path='/FAQ' element={<FAQ />}/>
        <Route path='/moviePlayer/:id' element={<MoviePlayer />}/>
        <Route path='/movies' element={<Movies />}/>
        <Route path='/musics' element={<Musics />}/>
        <Route path='/musicPlayer/:id' element={<MusicPlayer />}/>
        <Route path='/myAccount' element={<MyAccount />}/>
        <Route path='/privacy' element={<Privacy />}/>
        <Route path='/terms-condition' element={<TC />}/>
        <Route path='/register' element={<Register />}/>
        <Route path='/registration' element={<Registration />}/>
        <Route path='/subscribe' element={<Subscribe />}/>
        <Route path='/subscribe2' element={<Subscribe2 />}/>
        <Route path='/admin-uploadItem' element={<UploadItem />}/>
        <Route path='/admin-listItem' element={<ListItems />}/>
      </Routes>
      <Footer></Footer>
      <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                    background: '#363636',
                    color: '#fff',
                    },

                    // Default options for specific types
                    success: {
                    duration: 3000,
                    theme: {
                        primary: 'green',
                        secondary: 'black',
                    },
                    },
                }}
            />
    </BrowserRouter>
  );
}

export default App;
