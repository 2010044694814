import React from 'react';

const FAQ = () => {
    return (
        <section className="pb-[100px] bg-[#241C24] bg-cover" style={{backgroundImage: "url(./assets/Images/plainbg.png)"}}>
            <div className="custom-container ">
                <div className=" w-[80%] mx-auto pt-[150px]">
                    <h1 className="uppercase text-3xl font-bold text-white text-left mb-[40px]">FREQUENTLY ASKED QUESTIONS</h1>
                    <div className="mb-5">
                        <p className="text-white text-lg text-left font-bold">1. What is OneHubPlay?</p>
                        <p className="text-white text-lg text-left ">OneHubPlay is a comprehensive streaming platform where users can watch movies, listen to music, read e-books, and listen to audiobooks online.</p>
                    </div>
                    <div className="mb-5">
                        <p className="text-white text-lg text-left font-bold">2. Do I need to sign up to use OneHubPlay?</p>
                        <p className="text-white text-lg text-left ">Yes, you need to create an account to access the content on OneHubPlay.</p>
                    </div>
                    <div className="mb-5">
                        <p className="text-white text-lg text-left font-bold">3. Is there a free trial available?</p>
                        <p className="text-white text-lg text-left ">Yes, we offer a 2-day free trial for new users. You can cancel anytime during the trial period without any charges.</p>
                    </div>
                    <div className="mb-5">
                        <p className="text-white text-lg text-left font-bold">4. How can I cancel my subscription?</p>
                        <p className="text-white text-lg text-left ">Click on 'MY ACCOUNT' at the top of your account and then look for the 'Subscription'. You can cancel your subscription by clicking on the 'MEMBERSHIP SETTINGS'.</p>
                    </div>
                    <div className="mb-5">
                        <p className="text-white text-lg text-left font-bold">5. Why are my upgrade attempts being rejected?</p>
                        <p className="text-white text-lg text-left ">In case your upgrade attempt is rejected please try at least 2 times more (sometimes it goes through after 2-3 attempts). Most of the time your bank/card issuer is rejecting an upgrade attempt because they put certain restrictions on your card. If you filled out your correct credit card number, expiration date & cvv and your upgrade attempt is still denied please call your bank/card issuer and ask them why they are rejecting the transaction. If it is because of certain restrictions, you can request them to remove those restrictions for billings from our company. Once your card issuer confirms the restrictions are removed try again. If you are still experiencing problems after you called your card issuer please contact us and let us know the reject reason your card issuer provided so we can investigate.</p>
                    </div>
                    <div className="mb-5">
                        <p className="text-white text-lg text-left font-bold">6. How do I change my email address or password?</p>
                        <p className="text-white text-lg text-left ">Click on 'MY ACCOUNT' at the top of your account and then look for the 'Account Details'. You can change your email address and password by clicking on the pen icon. Make sure to click on the 'Save' button.</p>
                    </div>
                    <div className="mb-5">
                        <p className="text-white text-lg text-left font-bold">7. Who to contact for billing related questions?</p>
                        <p className="text-white text-lg text-left ">If you have a billing related question you can either send an email to support@onehubplay.com or you can call our billing department number below:</p>
                        <p className="text-white text-lg text-left ">International Phone: +1 (325) 298-0325</p>
                        <p className="text-white text-lg text-left ">Please only use the above number for billing related questions. If you have site related questions contact us by email.</p>
                    </div>
                    <div className="mb-5">
                        <p className="text-white text-lg text-left font-bold">8. What are the membership prices and payment options?</p>
                        <p className="text-white text-lg text-left ">After the 2-day free membership period, subscription renews at $29.99 every month until canceled. We accept all credit/debit cards with a VISA or Mastercard logo.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FAQ;
