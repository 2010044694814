import React from 'react';

const CancelSubscription = ({setCancelModal}) => {
    return (
        <div className=' bg-[#BFBEBE] flex items-center justify-center absolute w-full h-full top-0 left-0 bg-opacity-60'>
            <div className='w-[500px] bg-white rounded-lg p-[50px]' >
                <h1 className='mb-[20px] text-[#817F80] text-[30px] text-center font-bold'>Cancel your membership</h1>
                <p className='text-base text-[#817F80] font-semibold text-center'>Hope to see you back soon!</p>
                <button onClick={() => setCancelModal(false)} className=" block bg-[#817F80] px-[40px] py-[10px] text-[16px] rounded-full mx-auto text-white font-semibold uppercase my-[20px] ">FINISH CANCELLATION</button>
                <p className='text-base text-[#817F80] font-bold text-center'>How about a limited-time deal: Continue your subscription now and enjoy one month on us, free of charge!</p>
                <button onClick={() => setCancelModal(false)}  className=" block bg-[#817F80] px-[40px] py-[10px] text-[16px] rounded-full mx-auto text-white font-semibold uppercase my-[20px] ">CLAIM YOUR FREE MONTH</button>
            </div>
        </div>
    );
}

export default CancelSubscription;
