import React,{useState, useEffect} from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';
import { baseUrl } from '../Utils/app';


const AudioBookPlayer = () => {

    let {id} = useParams();

    let loadingToast;
    const [loading,setLoading] = useState(true);
    const [audioBook, setAudioBook] = useState([]);

    useEffect(()=>{
        // setLoading(true);
        loadingToast = toast.loading("Loading");

        if(id){
            const getItems = async () => {
                return await axios.get(`${baseUrl}/api/item/${id}`)
             }
     
             getItems().then((resp) => {
                setAudioBook(resp.data.data.item)
                toast.dismiss(loadingToast)
                setLoading(false);
             }).catch((error)=>{
                 console.log(error)
                 toast.dismiss(loadingToast)
                 toast.error(error?.message);
             });
        }
        
    },[id])

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[]);

    return (
        <>
            {
                !loading ? (
                    <section className="py-[100px] bg-[#241C24] bg-cover" style={{backgroundImage: 'url(/assets/Images/bgsec1.png)'}}>
                        <div className="custom-container ">
                            <div className='w-[80%] mx-auto pt-[150px] mb-[100px]'>
                                <ReactAudioPlayer className='w-full' src={audioBook?.file_link} controls volume />
                            </div>
                            <div className=" flex  gap-x-[50px] items-center">
                                <div className=" flex-1  bg-red-50">
                                    <img className="w-full object-cover h-[450px]" src={audioBook?.cover_image_small} />
                                </div>
                                <div className="flex-[2]">
                                    <h1 className="text-[#18AD58] text-[50px] font-bold uppercase">{audioBook?.title}</h1>
                                    <h3 className="text-white font-semibold text-xl">Genre(s): {audioBook?.genre}</h3>
                                    <h3 className="text-white mb-1 font-semibold text-xl">Language: {audioBook?.production_country}</h3>
                                    <p className="text-white text-base mb-5">
                                        {audioBook?.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                    </section>
                ):(
                    <section className="h-screen bg-[#241C24] bg-cover flex items-center justify-center" style={{backgroundImage: 'url(./assets/Images/plainbg.png)'}}>
                        <img src={'/assets/Images/Eclipse-1s-200px.svg'}></img>
                    </section>
                ) 
            }
        </>
    );
}

export default AudioBookPlayer;
