import React, { useReducer, useContext, createContext } from 'react';

// Define the initial state
const initialState = {
  isAuth: false,
  userId: null
};

// Create the authentication reducer
function authReducer(state, action) {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, isAuth: true };
    case 'LOGOUT':
      return { ...state, isAuth: false };
    // case 'LOGINWITHID':
      // return { ...state, isAuth: true, userId:action?.userId };
    default:
      return state;
  }
}

// Create the authentication context
const AuthContext = createContext();

// Create the AuthProvider component
export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
}

// Custom hook for using the auth context
export function useAuth() {
  return useContext(AuthContext);
}
