import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { baseUrl } from '../Utils/app';
import ItemCard from './Components/ItemCard';


const EBooks = () => {

    let loadingToast;
    const [loading,setLoading] = useState(true);
    const [eBooks, setEBooks] = useState([]);
    const [featured, setFeatured] = useState();

    useEffect(()=>{
        setLoading(true);
        loadingToast = toast.loading("Loading");

        const getItems = async () => {
           return await axios.get(`${baseUrl}/api/ebooks`)
        }

        getItems().then((resp) => {
            setEBooks(resp.data.data.ebooks)
            setFeatured(resp.data.data.featured)
            toast.dismiss(loadingToast)
            setLoading(false);
        }).catch((error)=>{
            console.log(error)
            toast.dismiss(loadingToast)
            toast.error(error?.message);
        });

    },[]);

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[]);

    return (
        <>
        {
            !loading ? (
                <>
                    {/* <Swiper
                        modules={[Navigation, Pagination, A11y]}
                        spaceBetween={0}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        >
                            {
                                    featured.length > 0 && featured.map((item) => <SwiperSlide key={item.id}>
                                        <section className="h-screen bg-[#241C24] bg-cover" style={{backgroundImage: `url(${item?.cover_image_large})`}}>
                                            <div className="custom-container flex items-center h-full">
                                                <div className="w-[50%] min-w-[500px]">
                                                    <h2 className="text-2xl tracking-[15px] text-white ">E-BOOKS</h2>
                                                    <h1 className="text-[#18AD58] text-[50px] font-bold uppercase"> <Link to={"/eBookReader/"+item.id}>{item?.title}</Link> </h1>
                                                    <p className="text-white text-xl mb-6 font-semibold">{item?.rating}/10 • {item?.duration} .  {item?.release_date}</p>
                                                    <p className="text-white text-xl mb-7">
                                                        {item?.description}
                                                    </p>
                                                    <Link to={"/eBookReader/"+item.id} className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold">READ NOW</Link>
                                                </div>
                                            </div>
                                        </section>
                                    </SwiperSlide>
                                )
                            }
                    </Swiper> */}
                    <section className="h-screen bg-[#241C24] bg-cover" style={{backgroundImage: `url(${featured?.cover_image_large})`}}>
                        <div className="custom-container flex items-center h-full">
                            <div className="w-[50%] min-w-[500px]">
                                <h2 className="text-2xl tracking-[15px] text-white ">E-BOOKS</h2>
                                <h1 className="text-[#18AD58] text-[50px] font-bold uppercase"> <Link to={"/eBookReader/"+featured?.id}>{featured?.title}</Link> </h1>
                                <p className="text-white text-xl mb-6 font-semibold">{featured?.rating}/10 • {featured?.duration} •  {featured?.release_date}</p>
                                <p className="text-white text-xl mb-7">
                                    {featured?.description}
                                </p>
                                <Link to={"/eBookReader/"+featured?.id} className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold">READ NOW</Link>
                            </div>
                        </div>
                    </section>
                    <section className="pt-[100px] pb-[50px] bg-[#241C24] bg-cover mt-[-2px]" style={{backgroundImage: "url(/assets/Images/sec3bg.png)"}}>
                        <div className="custom-container">
                            <div className=" mx-auto">
                                <div className="flex justify-between mb-[44px]">
                                    <h2 className=" text-white text-3xl font-bold ">Books</h2>
                                </div>
                                <div className="grid grid-cols-5 gap-x-[30px] gap-y-[40px]">
                                    {
                                        eBooks?.length > 0 && eBooks?.map(
                                            (item) => <ItemCard routeName={"/eBookReader/"} item={item}></ItemCard>
                                        )
                                    }
                                </div>
                                <div className="mt-[80px]">
                                    <button className=" block bg-[#18AD58] px-[40px] py-[5px] text-[16px] rounded-md text-white font-semibold uppercase mx-auto">Show More</button>
                                </div>
                            </div>

                        </div>
                    </section>
                </>
            ):(
                <section className="h-screen bg-[#241C24] bg-cover flex items-center justify-center" style={{backgroundImage: 'url(./assets/Images/plainbg.png)'}}>
                    <img src={'/assets/Images/Eclipse-1s-200px.svg'}></img>
                </section>
            ) 
        }
        </>
    );
}

export default EBooks;
