import React,{useEffect} from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

const Register = () => {
    const { state, dispatch } = useAuth();
    const navigate = useNavigate();

    const handleLogin = () => {
        // dispatch({ type: 'LOGIN' });
        navigate('/subscribe')
    };

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[]);

    
    return (
        <div className=' h-screen bg-[#BFBEBE] flex items-center justify-center'>
            <div className='w-[500px] bg-white rounded-lg p-[50px]' >
                <h1 className='mb-[20px] text-gray-500 text-3xl text-center font-semibold'>Create your account</h1>
                <div className='mb-[10px]'>
                    <label className='mb-[5px] text-gray-500 block font-semibold'>Enter your email</label>
                    <input className='p-2 border border-gray-500 block rounded-md w-full mb-[5px]' />
                    <p className='text-xs text-gray-500'>By clicking the continue button, you confirm your email address is valid.</p>
                </div>
                <div className='mb-[20px]'>
                    <label className='mb-[5px] text-gray-500 block font-semibold'>Password</label>
                    <input type='password' className='p-2 border border-gray-500 block rounded-md w-full mb-[10px]' />
                    <p className='text-sm text-gray-500 mb-3'>Already have an account? <a href='#' className='underline'>Click here</a> to login.</p>
                    <label className='text-xs text-gray-500 flex gap-x-2 items-start'>
                        <input className='mt-[5px]' type='checkbox' /> 
                        <span className='block'>
                            I agree to the <Link to={"/terms-condition"} class=" underline">Terms and Conditions</Link>, <Link to={"/privacy"} class=" underline">Privacy Policy</Link> and <Link to={"/cookie"} class=" underline">Cookie Policy</Link>. I confirm I am 18 years or older
                            and agree to receive emails from onehubplay.com.
                        </span>
                    </label>
                </div>
                <button onClick={() => handleLogin()} className=" block bg-[#18AD58] px-[40px] py-[5px] text-[20px] rounded-full mx-auto text-white font-semibold uppercase ">Continue</button>
            </div>
        </div>
    );
}

export default Register;
