import React,{useEffect} from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

const Subscribe = () => {
    const { state, dispatch } = useAuth();
    const navigate = useNavigate();

    const handleLogin = () => {
        dispatch({ type: 'LOGIN' });
        navigate('/home')
    };

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[]);

    return (
        <div className=' pb-[100px] bg-[#fff] pt-[50px]' >
            <img src="./assets/Images/logo.png" className='w-[200px] block mb-[20px] mx-auto' />
            <h1 className='text-gray-500 font-bold text-[40px] text-center mb-[50px]'>100% UNLIMITED ACCESS</h1>
            <div className='w-[60%] mx-auto'>
                <div className='grid grid-cols-2 gap-x-[30px]'>
                    <div className='bg-[#F8F9F9] p-[20px]' style={{backgroundImage: "url(/assets/Images/bg1.png)"}}>
                        <h2 className='text-xl text-gray-600 font-semibold text-center'>VERIFY YOUR ACCOUNT</h2>
                        <p className='text-sm text-center mb-[15px]'>100% FREE SECURE ACTIVATION - <span className='text-green-500 font-extrabold '>$0.00</span></p>
                        <div className='flex gap-x-[10px] justify-center mb-[15px]'>
                            <div className='flex gap-x-[10px]'>
                                <svg width="16" height="16" fill='#FFC200' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.947 9.181a1 1 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.997.997 0 0 0-1.822-.001L8.622 8.052l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.204l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065Z"></path>
                                </svg>
                                <svg width="16" height="16" fill='#FFC200' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.947 9.181a1 1 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.997.997 0 0 0-1.822-.001L8.622 8.052l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.204l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065Z"></path>
                                </svg>
                                <svg width="16" height="16" fill='#FFC200' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.947 9.181a1 1 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.997.997 0 0 0-1.822-.001L8.622 8.052l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.204l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065Z"></path>
                                </svg>
                                <svg width="16" height="16" fill='#FFC200' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.947 9.181a1 1 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.997.997 0 0 0-1.822-.001L8.622 8.052l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.204l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065Z"></path>
                                </svg>

                                <svg width="16" height="16" fill="none" stroke="#FFC200" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.5 9.75h-8.063L12 2.25l-2.438 7.5H1.5l6.563 4.5-2.532 7.5L12 17.062l6.469 4.688-2.532-7.5 6.563-4.5Z"></path>
                                    <path fill="#FFC200" stroke="none" d="M12 2.25v14.813L5.531 21.75l2.532-7.5L1.5 9.75h8.063L12 2.25Z"></path>
                                </svg>
                            </div>
                            <p className='text-sm'>264,235 USERS</p>
                        </div>
                        <p className='text-xs text-center mb-[20px]'>We respect your privacy. You wIll be discreetly billed as DORHAD.COM located in Guernsey</p>
                        <div className='grid grid-cols-2 gap-x-[15px]'>
                            <div className='mb-[10px]'>
                                <label className='mb-[5px] text-gray-500 block text-xs font-semibold'>First Name</label>
                                <input className='h-[30px] bg-transparent border border-gray-400 block rounded w-full mb-[5px] px-[5px]' />
                            </div>
                            <div className='mb-[10px]'>
                                <label className='mb-[5px] text-gray-500 block text-xs font-semibold'>Last Name</label>
                                <input className='h-[30px] bg-transparent border border-gray-400 block rounded w-full mb-[5px] px-[5px]' />
                            </div>
                        </div>
                        <div className='mb-[10px]'>
                            <div className=' mb-[5px] flex justify-between items-center'>   
                                <label className=' text-gray-500 block text-xs font-semibold'>Credit Card Number</label>
                                <div className='flex gap-x-[5px]'>
                                    <img className='h-[10px] object-cover' src='/assets/Images/visa.png' />
                                    <img className='h-[10px] object-cover' src='/assets/Images/mcard.png' />
                                </div>
                            </div>
                            
                            <input className='h-[30px] bg-transparent border border-gray-400 block rounded w-full mb-[5px] px-[5px]'  />
                        </div>
                        <div className='grid grid-cols-3 gap-x-[15px]'>
                            <div className='mb-[10px]'>
                                <label className='mb-[5px] text-gray-500 block text-xs font-semibold'>Expiration Date</label>
                                <select className='h-[30px] bg-transparent border border-gray-400 block rounded w-full mb-[5px]'>
                                    <option>MM</option>
                                    <option value="01">01</option>
                                    <option value="02">02</option>
                                    <option value="03">03</option>
                                    <option value="04">04</option>
                                    <option value="05">05</option>
                                    <option value="06">06</option>
                                    <option value="07">07</option>
                                    <option value="08">08</option>
                                    <option value="09">09</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>
                            <div className='mb-[10px]'>
                                <label className='mb-[5px] text-gray-500 block text-xs font-semibold invisible'> d</label>
                                <select className='h-[30px] bg-transparent border border-gray-400 block rounded w-full mb-[5px]'>
                                    <option>YY</option>
                                    <option value="01">23</option>
                                    <option value="02">24</option>
                                    <option value="03">25</option>
                                    <option value="04">26</option>
                                    <option value="05">27</option>
                                    <option value="06">28</option>
                                    <option value="07">30</option>
                                    <option value="08">31</option>
                                    <option value="09">32</option>
                                    <option value="10">33</option>
                                    <option value="11">34</option>
                                    <option value="12">35</option>
                                </select>
                            </div>
                            <div className='mb-[10px]'>
                                <label className='mb-[5px] text-gray-500 block text-xs font-semibold'>CVV</label>
                                <input className='h-[30px] bg-transparent border border-gray-400 block rounded w-full mb-[5px] px-[5px]' />
                            </div>
                        </div>
                        <label className='text-xs text-gray-500 flex gap-x-2 items-start'>
                            <input className='mt-[5px]' type='checkbox' /> 
                            <span className='block'>
                                I have read and agree to the <Link to={"/terms-condition"} class=" underline">Terms of Service</Link> and our <Link to={"./"} class=" underline">Recurring Transactions Agreement</Link>. After the 2-day free membership period, subscription renews at $29.99 every month until canceled.
                            </span>
                        </label>
                        <button onClick={()=> handleLogin()} className=" block bg-[#18AD58] px-[40px] py-[5px] text-[20px]  rounded-full mx-auto text-white font-semibold uppercase mt-[20px]">Continue</button>
                        <div className='flex gap-x-[20px] justify-center my-[30px]'>
                            <img width={100} src='/assets/Images/norton.png' />
                            <img width={100} src='/assets/Images/privacy.png'/>
                            <img width={100} src='/assets/Images/secured.png'/>
                        </div>
                        <p className='text-xs text-center text-gray-500'> Billing issues phone support number: +1 (325) 298-0325</p>
                    </div>
                    <div className='bg-[#F8F9F9] p-[20px]' style={{backgroundImage: "url(/assets/Images/bg1.png)"}}>
                        <h2 className='text-xl text-gray-600 font-semibold text-center'>UNLOCK A WORLD OF PREMIUM DIGITAL CONTENT!</h2>
                        <img className='my-[40px]' src='./assets/Images/laptop.png' />
                        <p className='text-sm text-center font-semibold mb-[40px] text-gray-500'>SECURING MEMBERSHIP GRANTS YOU ACCESS TO:</p>
                        <div className='flex gap-x-[40px] justify-center my-[30px]'>
                            <div>
                                <img className='h-[40px] block mx-auto' src='/assets/Images/movies.png'/>
                                <p className='text-xs text-center text-gray-500'>Movies</p>
                            </div>
                            <div>
                                <img className='h-[40px] block mx-auto' src='/assets/Images/music.png' />
                                <p className='text-xs text-gray-500'>Music</p>
                            </div>
                            <div>
                                <img className='h-[40px] block mx-auto' src='/assets/Images/book.png'/>
                                <p className='text-xs text-gray-500'>Books</p>
                            </div>
                        </div>
                        <p className='text-xs mb-[20px] text-justify text-gray-500'>
                            A Credit card is used as a
                            location, so we can deliver licensed content in the correct language
                            we will not char your car Tor your
                            pre-authorization amount will be applied upon registration. At the
                            end of the free membership period, your free access wil
                            automatically convert to a paid subscription charging the amount of
                            49€ per month. unless vou cancel. The membershin is automaticalv
                            renewed everv month until vou cancel it. You can View all vour bill
                        </p>
                    </div>
                </div>

                <div className='mt-[100px] w-[50%] mx-auto text-center underline text-sm font-bold'>
                    <p>© 2023 CCP International Limited/onenubplay.com</p>
                    Suite 4, Level b Victoria House 29-31 Fign Street, St. Peter Port, Guernsev
                    GY1 2JX
                </div>
            </div>
        </div>
    );
}

export default Subscribe;
