import React,{useState, useEffect} from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import '@vidstack/react/player/styles/base.css';
import { MediaPlayer, MediaProvider } from '@vidstack/react';
import { PlayIcon } from '@vidstack/react/icons';
import ReactPlayer from 'react-player'
import { baseUrl } from '../Utils/app';


const MoviePlayer = () => {

    let {id} = useParams();

    let loadingToast;
    const [loading,setLoading] = useState(true);
    const [movie, setMovie] = useState([]);

    useEffect(()=>{
        setLoading(true);
        loadingToast = toast.loading("Loading");

        if(id){
            const getItems = async () => {
                return await axios.get(`${baseUrl}/api/item/${id}`)
             }
     
             getItems().then((resp) => {
                setMovie(resp.data.data.item)
                toast.dismiss(loadingToast)
                setLoading(false);
             }).catch((error)=>{
                 console.log(error)
                 toast.dismiss(loadingToast)
                 toast.error(error?.message);
             });
        }

        
    },[id])

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[]);

    return (
        <>
        {
            !loading ? (
            <>
                <section className="h-auto py-[150px] bg-[#241C24] bg-cover" style={{backgroundImage: "url(/assets/Images/bgsec1.png)"}}>
                    <div className="custom-container ">
                        <div className="h-[400px] w-[70%] mx-auto">
                            <ReactPlayer  controls volume playbackRate progressInterval pip playIcon  height={400} width={"100%"} url={movie?.file_link} />
                        </div>
                    </div>
                </section>
                <section className="pb-[100px] bg-[#241C24] mt-[-3]" style={{backgroundImage: "url(/assets/Images/sec3bg.png)"}}>
                    <div className="custom-container">
                        <div className=" flex pt-[50px] gap-x-[50px] items-center">
                            <div className=" flex-1  bg-red-50">
                                <img className="w-full object-cover h-[450px]"  src={movie?.cover_image_small}  />
                            </div>
                            <div className="flex-[2]">
                                
                                <h1 className="text-[#18AD58] text-[50px] font-bold uppercase">{movie?.title}</h1>
                                <h3 className="text-white mb-6 font-semibold text-xl">{movie?.rating}/10 • {movie?.duration} • {movie?.release_date}</h3>
                                <p className="text-white text-base mb-10">
                                    {movie?.description}
                                </p>
                                <p className="text-white text-base">
                                <span className="font-bold">GENRES:</span> {movie?.genre}
                                </p>
                                <p className="text-white text-base ">
                                    <span className="font-bold">PRODUCTION COUNTRY:</span> {movie?.production_country}
                                </p>
                                <p className="text-white text-base ">
                                    <span className="font-bold">DIRECTOR:</span> {movie?.author}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
            ):(
                <section className="h-screen bg-[#241C24] bg-cover flex items-center justify-center" style={{backgroundImage: 'url(./assets/Images/plainbg.png)'}}>
                    <img src={'/assets/Images/Eclipse-1s-200px.svg'}></img>
                </section>
            ) 
        }
        </>
    );
}

export default MoviePlayer;
