import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useLocation } from 'react-router-dom';

const Footer = () => {
    const { state, dispatch } = useAuth();
    const location = useLocation();
    

    return (
        <>
            {
                state.isAuth ? (
                    <section class="py-[20px] bg-[#241C24]">
                        <div class="custom-container flex justify-between items-center">
                            <div>
                                <p class=" text-sm text-[#fff] flex space-x-3"><Link to={"/terms-condition"} class="block underline">Terms and Conditions</Link> <Link to={"/privacy"} class="block underline">Privacy Policy</Link> <Link to={"/cookie"} class="block underline">Cookie Policy</Link></p>
                                <p class=" text-sm text-[#fff] flex space-x-3">Copyright © 2023 OneHubPlay. All Rights Reserved.</p>
                                <p class=" text-sm text-[#fff] flex space-x-3"><a href="mailto:support@onehubplay.com" className=' underline'>support@onehubplay.com</a></p>
                            </div>
                            <div>
                                <img width="150" src="/assets/Images/logo.png" />
                            </div>
                        </div>
                    </section>
                ):(
                        <>
                            {
                                (location.pathname != "/subscribe" && location.pathname != "/registration" && location.pathname != "/subscribe2" && location.pathname != "/Subscribe2") && <section class="py-[20px] bg-white">
                                    <div class="custom-container flex justify-between items-center">
                                        <div>
                                            <p class=" text-sm text-[#241C24] flex space-x-3"><Link to={"/terms-condition"} class="block underline">Terms and Conditions - {location.pathname}</Link> <Link to={"/privacy"} class="block underline">Privacy Policy</Link> <Link to={"/cookie"} class="block underline">Cookie Policy</Link></p>
                                            <p class=" text-sm text-[#241C24] flex space-x-3">Copyright © 2023 OneHubPlay. All Rights Reserved.</p>
                                            <p class=" text-sm text-[#241C24] flex space-x-3"><a href="mailto:support@onehubplay.com" className=' underline'>support@onehubplay.com</a></p>
                                        </div>
                                        <div>
                                            <img width="150" src="/assets/Images/logo.png" />
                                        </div>
                                    </div>
                                </section>
                            }
                            
                        </>
                    
                )
            }
        </>
        
    );
}

export default Footer;
