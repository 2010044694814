import React,{useState,useRef,useEffect} from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import axios from 'axios'
import AWS from 'aws-sdk';
import storage from '../Utils/firebase';
import { baseUrl } from '../Utils/app';

  
const s3 = new AWS.S3();

const UploadItem = () => {
    const { state, dispatch } = useAuth();
    const coverImageRef = useRef();
    const bannerImageRef = useRef();
    const fileRef = useRef();
    const navigate = useNavigate();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [formControls,setFormControls] = useState({
        'type':'movie',
        'description':'',
        'rating':'',
        'duration':'',
        'genre':'',
        'rating':'',
        'author':'',
        'release_date':'',
        'production_country':'',
        'title':'',
        'featured':false,
        'cover_image_small':'',
        'file_link':'',

    });
    const [loading,setLoading] = useState(false);
    let loadingToast;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        loadingToast = toast.loading("Loading");

        let mediaFileUrl='';
        let coverFileUrl='';
        let bannerFileUrl='';

        Promise.all([fileUploadPromise(coverImageRef.current.files[0]),fileUploadPromise(fileRef.current.files[0]),fileUploadPromise(bannerImageRef.current.files[0])])
            .then(async (results) => {
                console.log(results)
                coverFileUrl = results[0]
                mediaFileUrl = results[1]
                bannerFileUrl = results[2]

                let postData = {
                    ...formControls,
                    'cover_image_small':coverFileUrl,
                    'file_link':mediaFileUrl,
                    'cover_image_large':bannerFileUrl
                }
                axios.defaults.withCredentials = true;
                await axios.post(`${baseUrl}/api/contentItem`,postData).then((response) =>{
                    console.log(response)
                    setLoading(false)
                    toast.dismiss(loadingToast)
                    toast.success("Media created Successfully");
                    setFormControls({
                        'type':'movie',
                        'description':'',
                        'rating':'',
                        'duration':'',
                        'genre':'',
                        'rating':'',
                        'author':'',
                        'release_date':'',
                        'production_country':'',
                        'title':'',
                        'featured':false,
                        'cover_image_small':'',
                        'file_link':'',
                    })
                }).catch((error)=>{
                    console.log(error)
                    toast.dismiss(loadingToast)
                    toast.error(error?.message);
                    setLoading(false)
                });

            }).catch((e)=>{
                console.log(e)
                toast.error(e?.message);
            })
    }

    const handleFileChange = (fileInputRef,setUploadProgress) => {
        return new Promise(async (resolve, reject)=>{
            const file = fileInputRef.current.files[0];
            if (!file) {
                reject("File Not Present");
            }

            const fileKey = `uploads/${Date.now()}-${file.name}`;

            const params = {
                Bucket: 'uploads-23',
                Key: fileKey,
                Body: file,
            };

            const options = { partSize : 20 * 1024 * 1024, queueSize: 4 };

            s3.upload(params)
            .on('httpUploadProgress', (progress) => {
                const percentUploaded = (progress.loaded / progress.total) * 100;
                setUploadProgress(percentUploaded);
            })
            .send((err, data) => {
                if (err) {
                    console.error('Error uploading file:', err);
                    reject('Error uploading file:' +  err)
                } else {
                    console.log('File uploaded successfully:', data.Location);
                    resolve(data.Location)
                }
            });
        })
       

        
    };

    const fileUploadPromise = (imageFile) => {
        return new Promise (async (resolve, reject) => {
            if(imageFile){
                    let uuid = require("uuid");
                    try{
                        let uploadTask = storage.ref(`/fileUploads/${uuid.v4()+"."+imageFile.name.split(".")[1]}`).put(imageFile);
                        uploadTask.then(uploadSnapshot => {
                            return uploadSnapshot.ref.getDownloadURL();
                        }).then(url => {
                            resolve(url);
                        }).catch(err => {
                            reject(err);
                        })
                    }catch(e){
                        console.log(e)
                        reject(e);

                    }
            }else{
                resolve("");
            }
        })
    }

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[]);

    
    return (
        <div className=' py-[50px] bg-[#BFBEBE] flex items-center justify-center'>
            <form onSubmit={(e) => handleSubmit(e)} className='w-[70%] bg-white rounded-lg p-[50px]' >
                <h1 className='mb-[20px] text-gray-500 text-3xl text-center font-semibold'>Upload Media</h1>
                <div className='grid grid-cols-2 gap-x-[40px]'>
                    <div>
                        <div className='mb-[10px]'>
                            <label className='mb-[5px] text-gray-500 block font-semibold'>Media Type </label>
                            <select required value={formControls.type} onChange={(e) => setFormControls({...formControls, 'type':e.target.value})} className=' border border-gray-500 block rounded-md w-full mb-[5px] p-2'>
                                <option value="movie">Movie</option>
                                <option value="music">Music</option>
                                <option value="ebook">E-book</option>
                                <option value="audioBook">Audio Book</option>
                            </select>
                        </div>
                        <div className='mb-[10px]'>
                            <label className='mb-[5px] text-gray-500 block font-semibold'> Title </label>
                            <input value={formControls.title} required onChange={(e) => setFormControls({...formControls, 'title':e.target.value})} className='border border-gray-500 block rounded-md w-full mb-[5px] p-2' />
                        </div>
                        <div className='mb-[10px]'>
                            <label className='mb-[5px] text-gray-500 block font-semibold'> Description </label>
                            <textarea required value={formControls.description} rows={2} onChange={(e) => setFormControls({...formControls, 'description':e.target.value})} className='border border-gray-500 block rounded-md w-full mb-[5px] p-2'></textarea>
                        </div>
                        
                        {
                            formControls.type == 'movie' && <div className='mb-[10px]'>
                                <label className='mb-[5px] text-gray-500 block font-semibold'> Rating </label>
                                <input type='number' value={formControls.rating} required={formControls.type == "movie"} max={10} onChange={(e) => setFormControls({...formControls, 'rating':e.target.value})} className='border border-gray-500 block rounded-md w-full mb-[5px] p-2' />
                            </div>
                        }
                        {
                            (formControls.type == 'movie' || formControls.type == 'music')&& <div className='mb-[10px]'>
                                <label className='mb-[5px] text-gray-500 block font-semibold'> Duration </label>
                                <input value={formControls.duration} required={formControls.type == "movie" || formControls.type == 'music'} type='text'  onChange={(e) => setFormControls({...formControls, 'duration':e.target.value})} className='border border-gray-500 block rounded-md w-full mb-[5px] p-2' />
                            </div>
                        }
                        
                        <div className='mb-[10px]'>
                            <label className='mb-[5px] text-gray-500 block font-semibold'> Genre </label>
                            <input required type='text' value={formControls.genre} onChange={(e) => setFormControls({...formControls, 'genre':e.target.value})} className='border border-gray-500 block rounded-md w-full mb-[5px] p-2' />
                        </div>

                        <div className='mb-[20px]'>
                            <label className='text-gray-500 flex gap-x-2 items-start'>
                                <input value={false} onChange={(e) => setFormControls({...formControls, 'featured': !formControls.featured })} className='mt-[5px]' type='checkbox' />
                                <span className='block'>
                                   Set as Featured Item
                                </span>
                            </label>
                        </div>
                    </div>
                    <div>
                        <div className='mb-[10px]'>
                            <label className='mb-[5px] text-gray-500 block font-semibold'> Author </label>
                            <input required type='text' value={formControls.author} onChange={(e) => setFormControls({...formControls, 'author':e.target.value})} className='border border-gray-500 block rounded-md w-full mb-[5px] p-2' />
                        </div>
                        {
                            (formControls.type == 'movie' || formControls.type == 'music') &&  <div className='mb-[10px]'>
                                <label className='mb-[5px] text-gray-500 block font-semibold'> Release date </label>
                                <input type="number" id="yearInput" name="year" min="1900" max="2099" step="1" value={formControls.release_date} required={formControls.type == "movie" || formControls.type == 'music'} onChange={(e) => setFormControls({...formControls, 'release_date':e.target.value})} className='border border-gray-500 block rounded-md w-full mb-[5px] p-2' />
                            </div>
                        }
                        <div className='mb-[10px]'>
                            <label className='mb-[5px] text-gray-500 block font-semibold'> Production Country </label>
                            <input type='text' value={formControls.production_country} onChange={(e) => setFormControls({...formControls, 'production_country':e.target.value})} className='border border-gray-500 block rounded-md w-full mb-[5px] p-2' />
                        </div>
                        
                        <div className='mb-[10px]'>
                            <label className='mb-[5px] text-gray-500 block font-semibold'> Cover Image </label>
                            <input type='file' required ref={coverImageRef}  className='border border-gray-500 block rounded-md w-full mb-[5px] p-2' />
                        </div>
                        <div className='mb-[10px]'>
                            <label className='mb-[5px] text-gray-500 block font-semibold'> Cover Banner </label>
                            <input type='file' ref={bannerImageRef}  className='border border-gray-500 block rounded-md w-full mb-[5px] p-2' />
                        </div>
                        <div className='mb-[10px]'>
                            <label className='mb-[5px] text-gray-500 block font-semibold'> Media File </label>
                            <input type='file' ref={fileRef} required className='border border-gray-500 block rounded-md w-full mb-[5px] p-2' />
                        </div>
                    </div>
                </div>      
                
                {
                    loading ? (
                        <button type='button' className=" block bg-[#18AD58] px-[40px] py-[5px] text-[20px] rounded-full mx-auto text-white font-semibold uppercase mt-[30px]">
                            <img width={35} src={'/assets/Images/Eclipse-1s-200px.svg'}></img>
                        </button>
                    ): (
                        <button type='submit' className=" block bg-[#18AD58] px-[40px] py-[5px] text-[20px] rounded-full mx-auto text-white font-semibold uppercase mt-[30px]">Continue</button>
                    )
                }
                
            </form>
        </div>
    );
}

export default UploadItem;
