import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import CancelSubscription from './CancelSubscription';

const MyAccount = () => {

    const { state, dispatch } = useAuth();
    const [rating, setRating] = useState(0);
    const [cancelModal, setCancelModal] = useState(false)

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[]);

    const handleLogout = () => {
        dispatch({ type: 'LOGOUT' });
      };

    return (
        <section className=" bg-[#241C24] bg-cover pb-[100px] relative" style={{backgroundImage: "url(/assets/Images/bgsec1.png)"}}>
            <div className="custom-container ">
                <div className="pt-[100px]">
                    <h1 className="uppercase text-3xl font-bold text-white text-center mb-[30px]">Account</h1>
                    <p className="text-white text-xl text-center font-medium mb-2">Rate Us:</p>
                    <div className="flex justify-center mb-[40px]">
                        <svg onClick={() => setRating(1)} width="30" height="30" fill={rating >=1? "#18AD58":"none"} stroke="#18AD58" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m12 2 3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
                        </svg>
                        <svg onClick={() => setRating(2)} width="30" height="30" fill={rating >=2? "#18AD58":"none"} stroke="#18AD58" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m12 2 3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
                        </svg>
                        <svg onClick={() => setRating(3)} width="30" height="30" fill={rating >=3? "#18AD58":"none"} stroke="#18AD58" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m12 2 3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
                        </svg>
                        <svg onClick={() => setRating(4)} width="30" height="30" fill={rating >=4? "#18AD58":"none"} stroke="#18AD58" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m12 2 3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
                        </svg>
                        <svg onClick={() => setRating(5)} width="30" height="30" fill={rating >= 5? "#18AD58":"none"} stroke="#18AD58" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m12 2 3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
                        </svg>
                    </div>

                    <div className="w-[600px] h-auto mx-auto border-2 border-[#1B141A] mb-[50px]">
                        <div className="px-[30px] py-[15px] bg-[#1B141A]">
                            <p className="text-lg text-white">Account Details</p>
                        </div>
                        <div className="px-[30px] py-[15px] border-b-2 border-[#1B141A] flex justify-between">
                            <p className="text-base text-white opacity-50">Email</p>
                            <svg width="20" height="20" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 20h9"></path>
                                <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                              </svg>
                        </div>
                        <div className="px-[30px] py-[15px] border-b-2 border-[#1B141A] flex justify-between">
                            <p className="text-base text-white opacity-50">Password</p>
                            <svg width="20" height="20" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 20h9"></path>
                                <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                              </svg>
                        </div>
                        <div className="px-[30px] py-[15px]">
                            <button onClick={()=>handleLogout()} className="text-lg text-white uppercase text-center block mx-auto">Log out</button>
                        </div>
                    </div>

                    <div className="w-[600px] h-auto mx-auto border-2 border-[#1B141A] mb-[20px]">
                        <div className="px-[30px] py-[15px] bg-[#1B141A]">
                            <p className="text-lg text-white">Subscription</p>
                        </div>
                        
                        <div className="px-[30px] py-[15px]">
                            <button onClick={() => setCancelModal(true)}  className="text-lg text-white uppercase text-center block mx-auto" >MEMBERSHIP SETTINGS</button>
                        </div>
                    </div>

                    <button className=" block bg-[#18AD58] px-[40px] py-[5px] text-base rounded-md text-white font-semibold mx-auto">Save</button>
                </div>
            </div>

            {cancelModal && <CancelSubscription setCancelModal={setCancelModal}></CancelSubscription>}
        </section>
    );
}

export default MyAccount;
