import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { baseUrl } from '../Utils/app';


const ListItems = () => {
    const navigate = useNavigate();
    const [isAuth, setIsAuth] = useState(false)
    const [activeTab, setActiveTab] = useState(1);
    const [loading,setLoading] = useState(false);
    const [formControl, setFormControl] = useState({
        email: '',
        password: ''
    })
    const [items, setItems] = useState({
        'movies':[],
        'musics': [],
        'ebooks': [],
        'audioBooks': []
    });

    let loadingToast;

    useEffect(()=>{
        setLoading(true);
        loadingToast = toast.loading("Loading",);

        console.log(sessionStorage.getItem("isAuth"))
        if(sessionStorage.getItem("isAuth") == "true" || sessionStorage.getItem("isAuth") == true){
            setIsAuth(true)
        }

        const getItems = async () => {
           return await axios.get(`${baseUrl}/api/contentItem`)
        }

        getItems().then((resp) => {
            setItems(resp.data.data)
            toast.dismiss(loadingToast)
        }).catch((error)=>{
            console.log(error)
            toast.dismiss(loadingToast)
            toast.error(error?.message);
        })
    },[])

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[]);

    const handleLogin = (e) => {
        e.preventDefault();

        if(formControl.email == "admin@mail.com" && formControl.password == "adminPass1@"){
            setIsAuth(true)
            sessionStorage.setItem('isAuth', true)
        }else{
            toast.error("Incorrect Login Details")
        }
    };

    return (
        <>
        {
            isAuth ? (
                <div className=' h-auto py-[150px] bg-[#BFBEBE] flex items-center justify-center'>
                    <div className='w-[90%] bg-white rounded-lg p-[50px]' >
                        <h1 className='mb-[20px] text-gray-500 text-3xl text-center font-semibold'> Media Item List </h1>
                        <Link to={'/admin-uploadItem'} className=" block bg-[#18AD58] px-[40px] py-[5px] text-[20px] rounded-full mx-auto text-white font-semibold uppercase text-center mb-4">Upload New Video</Link>
                        <div>
                            <ul className='flex justify-evenly text-lg'>
                                <li><button onClick={()=>setActiveTab(1)} className={activeTab == 1? 'text-[#18AD58] font-bold':'text-gray-500'}>Movies</button> </li>
                                <li><button onClick={()=>setActiveTab(2)} className={activeTab == 2? 'text-[#18AD58] font-bold':'text-gray-500'}>Music</button></li>
                                <li><button onClick={()=>setActiveTab(3)} className={activeTab == 3? 'text-[#18AD58] font-bold':'text-gray-500'}>E-books</button></li>
                                <li><button onClick={()=>setActiveTab(4)} className={activeTab == 4? 'text-[#18AD58] font-bold':'text-gray-500'}>Audio Books</button></li>
                            </ul>
                        </div>
                        <div className='grid grid-cols-2'>
                            {
                                activeTab == 1 && <>
                                    {
                                        items?.movies?.length > 0 && items?.movies?.map((item) => <div className=" flex pt-[50px] gap-x-[20px]">
                                            <div className="w-[200px] ">
                                                <img className="w-full object-cover" src="../assets/Images/musicCover.png" />
                                            </div>
                                            <div className="flex-[2]">
                                                <h1 className="text-[#18AD58] text-[16px] font-bold">{item.title}</h1>
                                                <h3 className="mb-3 font-semibold text-xs text-gray-500">{item.rating}/10 • {item.duration} • {item.release_date}</h3>
                                                <p className="text-gray-500 text-xs mb-3">
                                                    {item.description}
                                                </p>
                                                <p className="text-gray-500 text-xs">
                                                <span className="font-bold">GENRES:</span> {item.genre}
                                                </p>
                                                <p className="text-gray-500 text-xs ">
                                                    <span className="font-bold">PRODUCTION COUNTRY:</span> {item.production_country}
                                                </p>
                                                <p className="text-gray-500 text-xs ">
                                                    <span className="font-bold">DIRECTOR:</span> {item.author}
                                                </p>
                                            </div>
                                        </div> )
                                    }
                                </>
                            }
                            {
                                activeTab == 2 && <>
                                    {
                                        items?.musics?.length > 0 && items?.musics?.map((item) => <div className=" flex pt-[50px] gap-x-[20px]">
                                            <div className="w-[200px] ">
                                                <img className="w-full object-cover" src="../assets/Images/musicCover.png" />
                                            </div>
                                            <div className="flex-[2]">
                                                <h1 className="text-[#18AD58] text-[16px] font-bold">{item.title}</h1>
                                                <h3 className="mb-3 font-semibold text-xs text-gray-500">{item.rating}/10 • {item.duration} • {item.release_date}</h3>
                                                <p className="text-gray-500 text-xs mb-3">
                                                    {item.description}
                                                </p>
                                                <p className="text-gray-500 text-xs">
                                                <span className="font-bold">GENRES:</span> {item.genre}
                                                </p>
                                                <p className="text-gray-500 text-xs ">
                                                    <span className="font-bold">Artist:</span> {item.author}
                                                </p>
                                            </div>
                                        </div> )
                                    }
                                </>
                            }
                            {
                                activeTab == 3 && <>
                                    {
                                        items?.ebooks?.length > 0 && items?.ebooks?.map((item) => <div className=" flex pt-[50px] gap-x-[20px]">
                                            <div className="w-[200px] ">
                                                <img className="w-full object-cover" src="../assets/Images/musicCover.png" />
                                            </div>
                                            <div className="flex-[2]">
                                                <h1 className="text-[#18AD58] text-[16px] font-bold">{item.title}</h1>
                                                <p className="text-gray-500 text-xs mb-3">
                                                    {item.description}
                                                </p>
                                            </div>
                                        </div> )
                                    }
                                </>
                            }
                            {
                                activeTab == 4 && <>
                                    {
                                        items?.audioBooks?.length > 0 && items?.audioBooks?.map((item) => <div className=" flex pt-[50px] gap-x-[20px]">
                                            <div className="w-[200px] ">
                                                <img className="w-full object-cover" src="../assets/Images/musicCover.png" />
                                            </div>
                                            <div className="flex-[2]">
                                                <h1 className="text-[#18AD58] text-[16px] font-bold">{item.title}</h1>
                                                <p className="text-gray-500 text-xs mb-3">
                                                    {item.description}
                                                </p>
                                            </div>
                                        </div> )
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            ):(
                <div className=' h-screen bg-[#BFBEBE] flex items-center justify-center'>
                    <form onSubmit={(e)=> handleLogin(e)} className='w-[500px] bg-white rounded-lg p-[50px]' >
                        <h1 className='mb-[20px] text-gray-500 text-3xl text-center font-semibold'>Create your account</h1>
                        <div className='mb-[10px]'>
                            <label className='mb-[5px] text-gray-500 block font-semibold'>Enter your email</label>
                            <input onChange={(e) => setFormControl({...formControl,'email': e.target.value})} type='email' required className='p-2 border border-gray-500 block rounded-md w-full mb-[5px]' />
                            <p className='text-xs text-gray-500'>By clicking the continue button, you confirm your email address is valid</p>
                        </div>
                        <div className='mb-[20px]'>
                            <label className='mb-[5px] text-gray-500 block font-semibold'>Password</label>
                            <input type='password' onChange={(e) => setFormControl({...formControl,'password': e.target.value})} className='p-2 border border-gray-500 block rounded-md w-full mb-[10px]' />
                            
                        </div>
                        <button type='submit' className=" block bg-[#18AD58] px-[40px] py-[5px] text-[20px] rounded-full mx-auto text-white font-semibold uppercase ">Continue</button>
                    </form>
                </div>
            )
        }
        </>
    );
}

export default ListItems;
