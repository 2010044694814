import React from 'react';
import { Link } from 'react-router-dom';

const ItemCard = ({ routeName, item }) => {
    return (
        <Link to={routeName + item.id} className="block h-[350px] relative group">
            <img className="h-[350px] object-cover" src={item?.cover_image_small} />
            <div className=" bg-[#241C24] opacity-90 p-3 group-hover:p-5 group-hover:h-full absolute bottom-0 left-0 right-0 z-10">
                <p className="text-white uppercase group-hover:hidden">{item?.title}</p>
                <div className='border-2 border-white h-full pt-[57px] text-center p-3 transition-all hidden group-hover:block ease-in-out delay-150 '>
                    <p className='text-white font-bold text-center text-xl uppercase mb-2'>{item?.title}</p>
                    <svg className=' mx-auto' width="46" height="46" fill='none' stroke="#fff" stroke-width="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 12c0-4.969-4.031-9-9-9s-9 4.031-9 9 4.031 9 9 9 9-4.031 9-9Z"></path>
                        <path fill="#fff" stroke="none" d="m10.14 15.676 5.365-3.241a.511.511 0 0 0 0-.872L10.14 8.322a.505.505 0 0 0-.765.436v6.481a.505.505 0 0 0 .765.437Z"></path>
                    </svg>
                </div>
            </div>
        </Link>
    );
}

export default ItemCard;
