import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';



const Index = () => {

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[]);
    
    return (
        <>
            <section className="h-screen w-full bg-cover max-h-[800px] bg-[#241C24]" style={{backgroundImage: "url(/assets/Images/sec1bg.png)"}}>
                <div className="custom-container h-full flex items-center justify-end">
                    <div className=" w-[400px] h-auto ">
                        <h1 className="text-[#18AD58] text-2xl font-bold mb-4">ENTERTAINMENT UNLEASHED</h1>
                        <p className="text-white text-lg text-justify mb-[50px]">Join us and unlock limitless access to a diverse library of
                            movies, music, and books. Dive into our curated catalog
                            and discover a world of popular titles at your fingertips.
                        </p>
                        <div>
                            <Link to={"/register"} className="  bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold">START NOW</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-[100px] pb-[100px] bg-[#241C24]">
                <div className="w-[80%] mx-auto">
                    <div className="grid grid-cols-3">
                        <div className=" flex flex-col">
                            <img className="w-full h-[300px] object-cover flex-shrink-0" src="/assets/Images/img1.png" />
                            <div className="px-[30px] py-[15px] h-full flex flex-col">
                                <div className="flex-1">
                                    <h2 className="text-[#18AD58] text-xl font-bold mb-4">
                                        UNLIMITED MOVIES!
                                    </h2>
                                    <p className="text-white text-base text-justify mb-[30px]">
                                        OneHubPlay transforms your
                                        living
                                        space into a cinematic paradise. From
                                        blockbusters and timeless classics to
                                        spine-chilling horror, we've got it all. Our
                                        ever-evolving
                                        collection
                                        ensures
                                        you
                                        always have top-tier movies at your 
                                        disposal.
                                    </p>
                                </div>

                                <div>
                                    <Link to={"/register"} className="flex-shrink-0  bg-[#18AD58] px-[40px] py-[10px] text-base rounded-xl text-white font-bold">WATCH NOW</Link>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img className="w-full h-[300px] object-cover" src="./assets/Images/img2.png" />
                            <div className="px-[30px] py-[15px]">
                                <h2 className="text-[#18AD58] text-xl font-bold mb-4">
                                    UNLIMITED MUSIC!
                                </h2>
                                <p className="text-white text-base text-justify mb-[30px]">
                                    Experience a melody for every moment
                                    with OneHubPlay! Dive into our music
                                    anytime, anywhere - be it lounging at
                                    home, grinding at work, breaking
                                    sweat at the gym, or being on the move.
                                    Revel in the rhythms and let the music
                                    elevate your everyday experiences!
                                </p>
                                <Link to={"/register"} className="flex-shrink-0  bg-[#18AD58] px-[40px] py-[10px] text-base rounded-xl text-white font-bold">LISTEN NOW</Link>
                            </div>
                        </div>
                        <div>
                            <img className="w-full h-[300px] object-cover" src="./assets/Images/img3.png" />
                            <div className="px-[30px] py-[15px]">
                                <h2 className="text-[#18AD58] text-xl font-bold mb-4">
                                    UNLIMITED BOOKS!
                                </h2>
                                <p className="text-white text-base text-justify mb-[30px]">
                                    Our extensive collection of e-books and audiobooks ensures that fitting reading or listening into your hectic schedule is a breeze. Whether you're learning, seeking entertainment, or immersing yourself in thrilling tales, our digital library is accessible anytime, anywhere.
                                </p>
                                <Link to={"/register"} className="flex-shrink-0  bg-[#18AD58] px-[40px] py-[10px] text-base rounded-xl text-white font-bold">READ NOW</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Index;
