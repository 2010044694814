import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import CreateAccount2 from './CreateAccount2';

const Registration = () => {
    const [cancelModal, setCancelModal] = useState(false)

    return (
        <div className={cancelModal? 'relative h-screen overflow-hidden':''}>
            <header className='bg-white py-2'>
                <div class="custom-container">
                    <div class=" flex justify-between items-center ">
                        <div>
                            <img width="150" src="/assets/Images/logo.png" />
                        </div>
                        <div>
                            <h2 className=' text-[#6E6E6E]'>Entertainment Unleashed</h2>
                        </div>
                        <div class="flex space-x-1 items-center">
                            <button onClick={() => setCancelModal(true)}>
                                <img width={15} src='/assets/Images/topbar1.png' />
                            </button>
                            <button>
                                <img width={15} src='/assets/Images/topbar2.png' />
                            </button >
                        </div>
                    </div>
                </div>
            </header>


            <section className='py-[50px] bg-cover' style={{backgroundImage: "url(/assets/Images/registrationBg1.png)"}}>
                <div className='flex gap-x-[40px] justify-between my-[30px] w-[40%] mx-auto '>
                    <div>
                        <img className='h-[60px] block mx-auto' src='/assets/Images/sec1img1.png'/>
                        <p className='text-base  text-gray-400 text-center'>Secure</p>
                    </div>
                    <div>
                        <img className='h-[60px] block mx-auto' src='/assets/Images/sec1img2.png' />
                        <p className='text-base text-gray-400 text-center'>Premium</p>
                    </div>
                    <div>
                        <img className='h-[60px] block mx-auto' src='/assets/Images/sec1img3.png'/>
                        <p className='text-base text-gray-400 text-center'>All Devices</p>
                    </div>
                </div>
            </section>


            <section className='py-[50px] bg-contain bg-no-repeat' style={{backgroundImage: "url(/assets/Images/registrationBg2.png)"}}>
                <div className=' my-[30px] w-[40%] mx-auto text-center'>
                    <h1 className='font-normal text-lg text-gray-600 text-center mb-5'>ONLINE REGISTRATION</h1>
                    <button onClick={() => setCancelModal(true)} className="  bg-[#18AD58] px-[50px] py-[15px] text-[40px] rounded-xl text-white font-bold uppercase">Continue</button>
                    <div className='flex items-center justify-center space-x-[2px] mt-4 text-xs'>
                        <span className='w-2 h-2 rounded-full bg-[#18AD58] block'></span>
                        <span className='block text-xs text-gray-600'>
                            Available on every browser
                        </span>
                    </div>
                </div>
            </section>


            <section className='py-[50px] bg-cover' style={{backgroundImage: "url(/assets/Images/registrationBg3.png)"}}>
                <div className='flex gap-x-[40px] justify-between my-[30px] w-[70%] mx-auto '>
                    <div className='flex-1'>
                        <img className='h-[60px] block mx-auto' src='/assets/Images/movies.png' />
                        <p className='text-lg text-center text-gray-500'>Movies</p>
                    </div>
                    <div className='flex-1 border-r border-l border-gray-500'>
                        <img className='h-[60px] block mx-auto' src='/assets/Images/music.png' />
                        <p className='text-lg text-gray-500 text-center'>Music</p>
                    </div>
                    <div className='flex-1'>
                        <img className='h-[60px] block mx-auto' src='/assets/Images/book.png' />
                        <p className='text-lg text-gray-500 text-center'>Books</p>
                    </div>
                </div>
            </section>


            <section className='py-[50px] bg-cover' style={{backgroundImage: "url(/assets/Images/registrationBg4.png)"}}>
                <div className='flex gap-x-[40px] justify-between my-[30px] w-[70%] mx-auto '>
                    <div className='flex-1'>
                        <p className='text-lg text-center text-gray-500'>Register</p>
                        <img className='h-[220px] block mx-auto' src='/assets/Images/sec2img1.png' />
                    </div>
                    <div className='flex-1 pt-[120px]'>
                        <img className='h-[220px] block mx-auto' src='/assets/Images/sec2img2.png' />
                        <p className='text-lg text-gray-500 text-center'>Verify</p>
                    </div>
                    <div className='flex-1'>
                        <p className='text-lg text-gray-500 text-center'>Activate</p>
                        <img className='h-[220px] block mx-auto' src='/assets/Images/sec2img3.png' />
                    </div>
                </div>
            </section>

            <section className='py-[0px] bg-cover bg-[#F8F9F9]' >
                <div className='w-[80%] mx-auto'>
                    <div className='grid grid-cols-2'>
                        <div className=''>
                            <img src='/assets/Images/sec3img1.png' />
                        </div>
                        <div className=' px-5 flex items-center'>
                            <div>
                                <h2 className=' text-2xl text-gray-600 font-bold mb-6'>Enjoy endless movies, music,
                                <br/> books, and more?</h2>

                                <p className='text-gray-500 font-medium'>
                                    Dive into the sea of choices, all ready to stream and enjoy instantly.Browse our rich catalog filled with movies, music and books.
                                    Unlock unlimited access to hundred of titles. it's a wonderland for those passionate about books, music and movies! With thousands of popular titles
                                    at your fingertips, enjoyment knows no bounds. Kickstart your subscription today!
                                </p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>


            <section className='pt-[50px] pb-[100px] bg-[#fff]'>
                <div className='w-[80%] mx-auto'>
                    <div className='flex flex-row gap-x-[40px] justify-center'>
                        <div className='h-[600px] w-[280px] bg-contain bg-no-repeat bg-red-50 pt-[150px] px-[15px]' 
                        style={{backgroundImage: "url(/assets/Images/sec4img1.png)"}}>
                            <h3 className='text-3xl text-gray-100 text-center mb-[100px]'>SAFE</h3>
                            <p className='text-center text-gray-500'>
                                A secure connection ensures your content access remains safe at all times. This safeguard provides peace of mind as you browse and enjoy the content.
                            </p>
                        </div>
                        <div className='h-[600px] w-[280px] bg-contain bg-no-repeat bg-red-50 pt-[150px] px-[15px]' 
                        style={{backgroundImage: "url(/assets/Images/sec4img1.png)"}}>
                            <h3 className='text-3xl text-gray-100 text-center mb-[100px]'>PREMIUM</h3>
                            <p className='text-center text-gray-500'>
                                Enjoy a complimentary membership for the initial 2 days. Should you choose not to cancel the subscription before the free trial concludes, it will 
                                automatically transition to a paid subscription at a rate of $29.99 per month, continuing until the subscription is cancelled.
                            </p>
                        </div>
                        <div className='h-[600px] w-[280px] bg-contain bg-no-repeat bg-red-50 pt-[130px] px-[15px]' style={{backgroundImage: "url(/assets/Images/sec4img1.png)"}}>
                            <h3 className='text-3xl text-gray-100 text-center mb-[100px]'>MULTIPLE <br/> DEVICES</h3>
                            <p className='text-center text-gray-500'>
                                Seamlessly connect and stream your cherished content across all your devices, wherever you are, whenever you desire. 
                                This feature enhances flexibility, allowing your entertainment to travel with you.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className='py-[100px] bg-cover bg-[#F8F9F9]' >
                <div className='w-[80%] mx-auto'>
                    <div className='grid grid-cols-3'>
                        <div className=' px-5 col-span-2'>
                            <div> <h2 className=' text-2xl text-gray-600 font-bold mb-4'>Why should you proceed with activation?</h2>
                                <ul className=''>
                                    <li className='flex gap-x-1 items-center text-gray-500'>
                                        <svg width="20" height="20" fill="none" stroke="#18AD58" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20 6 9 17l-5-5"></path></svg> <span>Regularly updated with fresh content</span> </li>
                                    <li className='flex gap-x-1 items-center text-gray-500'>
                                        <svg width="20" height="20" fill="none" stroke="#18AD58" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20 6 9 17l-5-5"></path> </svg> <span>Enjoyable on every device</span> </li>
                                    <li className='flex gap-x-1 items-center text-gray-500'>
                                        <svg width="20" height="20" fill="none" stroke="#18AD58" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20 6 9 17l-5-5"></path> </svg> <span>Enhanced safety measures</span>  </li>
                                    <li className='flex gap-x-1 items-center text-gray-500'>
                                        <svg width="20" height="20" fill="none" stroke="#18AD58" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20 6 9 17l-5-5"></path> </svg> <span>Absolutely no ads</span> </li>
                                </ul> </div> </div>
                        <div className=' px-5'>
                            <div> <h2 className=' text-2xl text-gray-600 font-bold mb-4'>How to Register:</h2> <ul>
                                    <li className='flex gap-x-1 items-center text-gray-500'> <span className=' text-[#18AD58] font-bold'>1.</span> <span>Set up an account</span> </li>
                                    <li className='flex gap-x-1 items-center text-gray-500'> <span className=' text-[#18AD58] font-bold'>2.</span> <span>Verify your account</span> </li>
                                    <li className='flex gap-x-1 items-center text-gray-500'> <span className=' text-[#18AD58] font-bold'>3.</span>  <span>Dive into your content</span> </li> </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='pt-[100px] pb-[100px] bg-[#fff]'>
                <div className='w-[80%] mx-auto'>
                    <div className='flex flex-row gap-x-[40px] justify-center'>
                        <div className='w-[280px] bg-contain bg-no-repeat ' >
                            <div className="w-full h-[245px] flex items-center justify-center bg-contain" style={{backgroundImage: "url(/assets/Images/sec6img1.png)"}}>                 
                                <img className='h-[80px]' src='/assets/Images/movies.png'/>
                            </div>
                            <h3 className='text-lg text-gray-600 text-center font-bold my-1'>Movies</h3>
                            <p className='text-center text-gray-500'>
                                Explore a big collection of movies, from old favorites to new hits. There's something for everyone. 
                                Our library is updated often, so there's always something new to keep you entertained.
                            </p>
                        </div>
                        <div className='w-[280px] bg-contain bg-no-repeat ' >
                            <div className="w-full h-[245px] flex items-center justify-center bg-contain" style={{backgroundImage: "url(/assets/Images/sec6img2.png)"}}>                 
                                <img className='h-[80px]' src='/assets/Images/music.png'/>
                            </div>
                            <h3 className='text-lg text-gray-600 text-center font-bold my-1'>Music</h3>
                            <p className='text-center text-gray-500'>
                                Find a big mix of music from all kinds of artists and times. Whether you feel like dancing to lively 
                                tunes or relaxing to soft classical music, there's something for every mood.
                            </p>
                        </div>
                        <div className='w-[280px] bg-contain bg-no-repeat' >
                            <div className="w-full h-[245px] flex items-center justify-center bg-contain" style={{backgroundImage: "url(/assets/Images/sec6img3.png)"}}>                 
                                <img className='h-[80px]' src='/assets/Images/book.png'/>
                            </div>
                            <h3 className='text-lg text-gray-600 text-center font-bold my-1'>Books</h3>
                            <p className='text-center text-gray-500'>
                                Check out our big selection of e-books and squeeze reading into your busy day. Learn, have fun, and dive into exciting stories anytime, anywhere.
                            </p>
                        </div>
                    </div>  
                    <div className='text-center mt-[100px]'>
                        <button onClick={() => setCancelModal(true)} className="  bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold ">Continue</button>
                    </div>
                </div>
            </section>

            <footer>
                <div className='w-[80%] text-gray-400 mx-auto text-center text-xs font-medium pb-[50px]'>
                    <p className='mb-4'>
                        Service and billing terms: Try the service for five (5) days. If you don't like it or find the service isn't for you, contact us to cancel for free. 
                        If you don't cancel, the service will automatically continue on a monthly basis at the end of the five (5) days free membership, and the service will automatically 
                        charge €49 to 
                        the credit card (debit cards are not accepted) at the beginning of each similar period, as long as you haven't cancelled the service. 
                        You can cancel the service at any time; cancelling stops the recurring fee. if you cancel the service, subscription payments are not refunded
                         retroactively, and previously charged subscription fees are not prorated based on the cancellation date. There is no obligation to purchase
                          a minimum amount of goods and no additional shipping or handling fee will be charged
                    </p>
                    <p className=' mb-4'> <Link to={"/terms-condition"}>Terms of Service</Link> | <Link to={"/privacy"}>Privacy Policy</Link> | <Link to={"/cookie"}>Cookie Policy</Link>  | Contact</p>
                    <p>Copyright © 2023 OneHubPlay.com</p>
                </div>
            </footer>

            {cancelModal && <CreateAccount2 setCancelModal={setCancelModal}></CreateAccount2>}

        </div>
    );
}

export default Registration;
